// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-accomodation-services-tsx": () => import("./../../../src/pages/accomodation-services.tsx" /* webpackChunkName: "component---src-pages-accomodation-services-tsx" */),
  "component---src-pages-company-registration-tsx": () => import("./../../../src/pages/company-registration.tsx" /* webpackChunkName: "component---src-pages-company-registration-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-enquire-tsx": () => import("./../../../src/pages/enquire.tsx" /* webpackChunkName: "component---src-pages-enquire-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-maritime-tsx": () => import("./../../../src/pages/maritime.tsx" /* webpackChunkName: "component---src-pages-maritime-tsx" */),
  "component---src-pages-overseas-tsx": () => import("./../../../src/pages/overseas.tsx" /* webpackChunkName: "component---src-pages-overseas-tsx" */),
  "component---src-pages-reviews-tsx": () => import("./../../../src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-tourism-services-tsx": () => import("./../../../src/pages/tourism-services.tsx" /* webpackChunkName: "component---src-pages-tourism-services-tsx" */),
  "component---src-pages-visa-applications-tsx": () => import("./../../../src/pages/visa-applications.tsx" /* webpackChunkName: "component---src-pages-visa-applications-tsx" */)
}

